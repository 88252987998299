import { z } from "zod";

const envSchema = z.object({
  VITE_WALLETCONNECT_PROJECT_ID: z.string(),
  VITE_BACKEND_ADDR: z.string(),
  VITE_BACKEND_API_KEY: z.string(),
  VITE_PART_PROJ_ID: z.string(),
  VITE_PART_CLIENT_KEY: z.string(),
  VITE_PART_APP_ID: z.string(),
});

export type Env = z.infer<typeof envSchema>;

const config = envSchema.parse(import.meta.env);

export { envSchema, config };
