import {
  connectorsForWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { FC, PropsWithChildren, useMemo } from "react";
import { config } from "./config";

import { ParticleNetwork } from "@particle-network/auth";
import { particleWallet } from "@particle-network/rainbowkit-ext";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";

import "@rainbow-me/rainbowkit/styles.css";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { peaqKrestChain } from "./peaqKrest";
import { PeaqKrest } from "@particle-network/chains";

const walletConnectProjectId = config.VITE_WALLETCONNECT_PROJECT_ID;

export const WagmiProvider: FC<PropsWithChildren> = ({ children }) => {
  const particle = useMemo(
    () =>
      new ParticleNetwork({
        projectId: config.VITE_PART_PROJ_ID,
        clientKey: config.VITE_PART_CLIENT_KEY,
        appId: config.VITE_PART_APP_ID,
        chainName: PeaqKrest.name,
        chainId: PeaqKrest.id,
        wallet: { displayWalletEntry: true },
      }),
    []
  );

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [peaqKrestChain],
    [
      () => ({
        chain: peaqKrestChain,
        rpcUrls: { http: peaqKrestChain.rpcUrls.default.http },
      }),
    ]
  );

  const commonOptions = { chains, projectId: walletConnectProjectId };

  const popularWallets = useMemo(() => {
    console.log("Initializing...", particle.version);
    return {
      groupName: "Popular",
      wallets: [
        particleWallet({ chains, authType: "google" }),
        particleWallet({ chains, authType: "facebook" }),
        particleWallet({ chains, authType: "apple" }),
        particleWallet({ chains }),
        metaMaskWallet(commonOptions),
      ],
    };
  }, [chains, commonOptions]);

  const connectors = connectorsForWallets([popularWallets]);

  const wagmiClient = createConfig({
    connectors,
    publicClient,
    webSocketPublicClient,
    autoConnect: false,
  });

  return (
    <WagmiConfig config={wagmiClient}>
      <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
    </WagmiConfig>
  );
};
